import { Container } from "react-bootstrap"
import "./Footer.css"
import logoFil from '../../imgs/logo/Logo_fil.png'
import { Link } from "react-router-dom"

import { AiOutlineRight } from 'react-icons/ai';
export default function Footer() {
    return (
        <div className="footer">
            <Container>
                <div className="footer_top">
                    <div className="footer_left">
                        <img src={logoFil} />
                        <h2>Liên hệ</h2>
                        <p>+84 0372 41 49 79</p>
                        <p>nghia.tran@fil.com.vn</p>
                    </div>
                    <div className="footer_center">
                        <div className="helpful"><h4>CÔNG TY TNHH FIL VIỆT NAM</h4>
                            <ul>
                                <li><p>TRỤ SỞ CHÍNH: Tầng 3, số 43, Le Parc, Gamuda city, Yên Sở, Hoàng Mai, Hà Nội</p>
                                </li>
                                <li><p>VP ĐÀ NẴNG: 12 Trịnh Đình Thảo, Khuê Trung, Cẩm Lệ</p></li>
                                <li><p>VP HCM: Tầng 2, số 100, Điện Biên Phủ, Quận 1</p></li>
                                {/* <li><p>Điện thoại: 0971175789</p></li>
                                <li><p>Email: hung.ly@fil.com.vn</p></li> */}

                                <li></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer_center">
                        <div className="helpful"><h4>GIỚI THIỆU</h4>
                            <ul>
                                <li><p>Tầm Nhìn: Vietnam FIL trở thành đơn vị tư vấn và cung cấp giải pháp cho hệ thống khí nén, thu hồi bụi số 1 Việt Nam.</p>
                                </li>
                                <li><p>Sứ Mệnh: Chúng tôi mang lại giá trị tối đa cho khách hàng bằng các giải pháp khí nén và thu hồi bụi tiên tiến, nâng cao hiệu suất sản xuất, tiết kiệm năng lượng, bảo vệ môi trường.</p></li>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="contact_us"><h4>Address</h4>

                        <ul>
                            <li><p>Address: DS07, TOÀ DIAMOND, APEC MANDALA CHAM BAY MŨI NÉ</p></li>
                        </ul>
                    </div> */}
                </div>
                <div className="footer_bot" >
                    <p>Bản quyền ở dưới thuộc sở hữu của FIL VN</p>
                </div>
            </Container>
        </div>

    )
}