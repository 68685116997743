import { Container } from 'react-bootstrap';
import './Booking.css'
import CalendarComponent from '../../components/calender/Calender'
import baner_contact from '../../imgs/baners/baner_contact.jpg'
export default function Booking() {
    const backgroundStyle1 = {
        backgroundImage: `url(${baner_contact})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    };
    return (
        <div>
            <div style={backgroundStyle1} className="baner_contact" data-aos="fade-down">
                <Container>
                    <h1>Booking Now</h1>
                </Container>
            </div>
            <Container>
                <CalendarComponent />
            </Container>

        </div>
    )
}