import "./Oil_free_compressors.css"

import { Container, Row, Col } from "react-bootstrap";
import { useContext, useState } from "react";
import { AppContext } from "../../../AppContext";
import { Link } from "react-router-dom";
import { BsChevronRight } from 'react-icons/bs';
import banerproduct from "../../../imgs/baners/baner_maynen/Oil-free-background-1.jpg"

import banerproduct_1 from "../../../imgs/baners/baner_maynen/OF-Product-home-Landing-Page.jpg"
import banerproduct_of from "../../../imgs/baners/baner_maynen/OF-90-Category-Image.png"
import banerproduct_ab from "../../../imgs/baners/baner_maynen/AB-Series-Category-image-new.jpg"
import banerproduct_renner from "../../../imgs/baners/baner_maynen/Renner.jpg"

export default function Oil_free_compressors() {
    const { products } = useContext(AppContext)
    const [filteredProducts, setFilteredProducts] = useState(products);
    const filterProducts = (type) => {
        if (type === "all") {
            const filtered = products;
            setFilteredProducts(filtered);
        }
        else {
            const filtered = products.filter((product) => product.type === type);
            setFilteredProducts(filtered);
        }
    };
    const backgroundStyle = {
        backgroundImage: `url(${banerproduct})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    };
    return (
        <nav className="product_list">
            <div style={backgroundStyle} className="baner_product" data-aos="fade-down">
                <Container>
                    <h1>MÁY NÉN KHÍ TRỤC VÍT KHÔNG DẦU</h1>
                </Container>
            </div>
            <div>
                <Container className="breadcrumb">
                    <Link to={"/"}>TRANG CHỦ</Link>
                    <Link to={"/compressor"}>/ MÁY NÉN KHÍ </Link>
                    <Link style={{ color: 'red' }} to={"/compressor/oil-free-compressors"}>/ MÁY NÉN KHÍ TRỤC VÍT KHÔNG DẦU</Link>
                </Container>
            </div>
            <Container >
                <div>
                    <Container>
                        <div className="products_home">
                            <div className="product">
                                <img src={banerproduct_of} />
                                <div className="product_content">
                                    <h2>Máy nén khí Elgi-OF series</h2>
                                    <p>Máy nén khí bôi trơn bằng dầu của ELGi là xương sống của nhiều ứng dụng công nghiệp khác nhau. Dòng máy nén khí bôi trơn bằng dầu mang đến các giải pháp khí nén lâu dài, tiết kiệm chi phí, phù hợp cho các ứng dụng công nghiệp từ nhẹ đến nặng.</p>
                                    <div className="button">
                                        <Link to={"/"}>
                                            <button className="button_vew">XEM CHI TIẾT</button>
                                        </Link>
                                        <Link to={"/"}>
                                            <button className="button_contact">LIÊN HỆ</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <div>
                    <Container>
                        <div className="products_home">
                            <div className="product">

                                <div className="product_content">
                                    <h2>Máy nén khí Elgi-AB series</h2>
                                    <p>Máy nén khí bôi trơn bằng dầu của ELGi là xương sống của nhiều ứng dụng công nghiệp khác nhau. Dòng máy nén khí bôi trơn bằng dầu mang đến các giải pháp khí nén lâu dài, tiết kiệm chi phí, phù hợp cho các ứng dụng công nghiệp từ nhẹ đến nặng.</p>
                                    {/* <p>Xem tất cả</p> */}
                                    <div className="button">
                                        <Link to={"/"}>
                                            <button className="button_vew">XEM CHI TIẾT</button>
                                        </Link>
                                        <Link to={"/"}>
                                            <button className="button_contact">LIÊN HỆ</button>
                                        </Link>
                                    </div>
                                </div>
                                <img src={banerproduct_ab} />
                            </div>
                        </div>
                    </Container>
                </div>
                <div>
                    <Container>
                        <div className="products_home">
                            <div className="product">
                                <img src={banerproduct_renner} />
                                <div className="product_content">
                                    <h2>Máy nén khí Renner series</h2>
                                    <p>Máy nén khí bôi trơn bằng dầu của ELGi là xương sống của nhiều ứng dụng công nghiệp khác nhau. Dòng máy nén khí bôi trơn bằng dầu mang đến các giải pháp khí nén lâu dài, tiết kiệm chi phí, phù hợp cho các ứng dụng công nghiệp từ nhẹ đến nặng.</p>
                                    {/* <p>Xem tất cả</p> */}
                                    <div className="button">
                                        <Link to={"/"}>
                                            <button className="button_vew">XEM CHI TIẾT</button>
                                        </Link>
                                        <Link to={"/"}>
                                            <button className="button_contact">LIÊN HỆ</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </Container>
        </nav >
    )
}