import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { CiFacebook } from 'react-icons/ci';
import { HiMenuAlt2 } from 'react-icons/hi';
import { GiRotaryPhone } from "react-icons/gi";
import "./Header.css"
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import logoFil from '../../imgs/logo/Logo_fil.png'
export default function Header() {
    const { handle_click, handle_click_close, show, setShow, handle_click_cart, handle_click_cart_close, showcart, setShowcart, handle_click_menu, close_menu, shownav, setShownav
        , backrgoudNav, setBackrgoudNav, quanlity, cart, changqty, delete1
        , handleSearchChange, handleSearchSubmit, searchText
    } = useContext(AppContext)
    return (
        <div className='vav'>
            
            <div className='header'>
                {/* <div className='top_header'>
                    <Container>
                        <div className='top_header_content'>
                            <p>Phone: 0372 41 49 79</p>
                            <p>Email: nghia.tran@fil.com.vn</p>
                        </div>
                    </Container>
                </div> */}
                <Navbar expand="lg" className={`navbar ${backrgoudNav ? "setbackgroud" : ""}`} >
                    <Container className='container'>
                        <div className='nav_icon'>
                            <HiMenuAlt2 onClick={handle_click_menu} />
                        </div>
                        <div className='header_left'>
                            <Link to={"/"} style={{ textDecoration: 'none' }} >
                                <img src={logoFil} />
                            </Link>
                        </div>
                        <div className='header_right'>
                            <div className={`menu ${shownav ? "activemenu" : ""}`}>
                                <ul onClick={close_menu}>
                                    <li><Link to={"/"} style={{ textDecoration: 'none' }}><h3>TRANG CHỦ</h3></Link></li>
                                    <li><Link to={"/"} style={{ textDecoration: 'none' }}><h3>GIỚI THIỆU</h3></Link></li>
                                    <li><Link to={"/booking"} style={{ textDecoration: 'none' }}><h3>SẢN PHẨM
                                    <ul className='menu_child'>
                                            <li><Link to={"/compressor"}>Máy nén khí</Link>
                                                <ul className='menu_child-child'>
                                                    <li><Link to={"/compressor/oil-free-compressors"}>Máy nén khí trục vít không dầu</Link></li>
                                                    <li>Máy nén khí trục vít có dầu</li>
                                                    <li>Máy nén khí piston</li>
                                                    <li>Máy nén khí di động</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link to={"/"}>Phụ kiện và thiết bị</Link>
                                                <ul className='menu_child-child'>
                                                    <li>Bộ làm mát</li>
                                                    <li>Bộ lọc khí nén</li>
                                                    <li>Máy sấy khí</li>
                                                    <li>Thiết bị do hơi dầu</li>
                                                    <li>Bộ tách dầu nước</li>
                                                    <li>Bộ lọc xử lý vi sinh</li>
                                                    <li>Bộ lọc hơi nước</li>
                                                    <li>Bộ lọc nước</li>
                                                </ul>
                                            </li>
                                            <li>Thiết bị đo lường</li>
                                            <li>Máy tạo khí</li>
                                            <li>Hệ thống thu hồi bụi</li>
                                            <li>Lọc động cơ</li>
                                            <li>Chiếu sáng công nghiệp</li>
                                        </ul>
                                        </h3></Link></li>
                                    <li><Link to={"/rooms"} style={{ textDecoration: 'none' }} className='product'><h3>NGÀNH CÔNG NGHIỆP</h3></Link></li>
                                    <li><Link to={"/otherservices"} style={{ textDecoration: 'none' }}><h3>TIN TỨC</h3></Link></li>
                                    <li><Link to={"/contact"} style={{ textDecoration: 'none' }}><h3>LIÊN HỆ</h3></Link></li>
                                </ul>
                                <div className='div_close' onClick={close_menu}></div>
                            </div>
                            {/* <div className='contactFacebook'>
                                <a className="phone" href="tel: 0916811179">
                                   <i><GiRotaryPhone className='phoneIcon'/></i> <span className='numberPhone'>0916 811 179</span>
                                </a>
                                <a className='Facebook' href="https://www.facebook.com/groups/700736942097108/" target="_blank" rel="noopener noreferrer">GROUP FACEBOOK</a>
                                <a><Link className='linkmenuhappyhouse' to={"/otherservices/eatapec/happyhouse"}>Menu Happy House</Link></a>
                                <a className='iconFacebook' href="https://www.facebook.com/groups/700736942097108/" target="_blank" rel="noopener noreferrer"><CiFacebook /></a>
                            </div>
                            <div className='linkmenu'>
                                <Link to={"/otherservices/eatapec/happyhouse"}>
                                    <button>Menu</button>
                                </Link>
                            </div> */}

                        </div>
                    </Container>
                    <div>
                    </div>
                </Navbar>
            </div>
        </div>
    )
}