import { Container, Col, Row } from 'react-bootstrap';
import './Otherservices.css';
import sao5 from '../../imgs/baners/5sao.jpeg'
import giat_ui from '../../imgs/baners/giat-ui.jpeg'
import quan_gia from '../../imgs/baners/quan-gia.jpeg'
import bbq from '../../imgs/baners/bbq.jpg'


import tour_du_lich from '../../imgs/baners/tour-du-lich.jpeg'
import baner_contact from '../../imgs/baners/baner_contact.jpg'
import eat_baner from '../../imgs/baners/eatbaner.jpg'

import { Link } from 'react-router-dom';
export default function Otherservices() {
    const backgroundStyle1 = {
        backgroundImage: `url(${baner_contact})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    };
    return (
        <div className="OtherServices">
            <div style={backgroundStyle1} className="baner_contact" data-aos="fade-down">
                <Container>
                    <h1>Other Services</h1>
                </Container>
            </div>
            <Container>
                <Row>
                    <Col xs={12} sm={6} lg={3} md={4}>
                        <Link to={"/otherservices/quangia"}>
                            <div className='Container_otherservices'>
                                <img src={quan_gia}></img>
                                <h3>Dịch vụ quản gia phòng giá rẻ</h3>

                                <p>Hotline: 0916 811 179</p>
                                <p>Xem thêm</p>
                            </div>
                        </Link>

                    </Col>
                    <Col xs={12} sm={6} lg={3} md={4}>
                        <Link to={"/otherservices/giatui"}>
                            <div className='Container_otherservices'>
                                <img src={giat_ui}></img>
                                <h3>Dịch vụ giặt ủi lấy liền Happy House DS07</h3>
                                <p>Hotline: 0388 509 027</p>
                                <p>Xem thêm</p>
                            </div>
                        </Link>


                    </Col>
                    <Col xs={12} sm={6} lg={3} md={4}>
                        <Link to="/otherservices/eatapec">
                            <div className='Container_otherservices'>
                                <img src={eat_baner}></img>
                                <h3>Dịch vụ ăn uống tại Apec</h3>

                                <p>Hotline: 0916 811 179</p>
                                <p>Xem thêm</p>
                            </div>
                        </Link>
                    </Col>
                    <Col xs={12} sm={6} lg={3} md={4}>
                        <Link to="/otherservices/camtrai">
                            <div className='Container_otherservices'>
                                <img src={bbq}></img>
                                <h3>Thiết kế tổ chức cắm trại, gala dinner, tiệc BBQ theo yêu cầu</h3>
                                <p>Hotline: 0916 811 179</p>
                                {/* <p>Xem thêm</p> */}
                            </div>
                        </Link>
                    </Col>
                    <Col xs={12} sm={6} lg={3} md={4}>
                        <Link to="/otherservices/tour">
                            <div className='Container_otherservices'>
                                <img src={tour_du_lich}></img>
                                <h3>Thiết kế tour du lịch theo yêu cầu</h3>

                                <p>Hotline: 0916 811 179</p>
                                <p>Xem thêm</p>
                            </div>
                        </Link>
                    </Col>
                    <Col xs={12} sm={6} lg={3} md={4}>
                        <Link to="/otherservices/canho5sao">
                            <div className='Container_otherservices'>
                                <img src={sao5}></img>
                                <h3>Căn hộ dịch vụ chuẩn 5 sao</h3>

                                <p>Hotline: 0916 811 179</p>
                                <p>Xem thêm</p>
                            </div>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}