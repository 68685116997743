import "./Air_drying.css"

import { Container, Row, Col } from "react-bootstrap";
import { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import { Link } from "react-router-dom";
import { BsChevronRight } from 'react-icons/bs';
import Card_product from "../../components/products/card_product/Card_product"


import banerproduct from "../../imgs/baners/baner_maynen/Oil-free-background-1.jpg"

import banerproduct_dry from "../../imgs/baners/baner_airdrying/compressed-air-dryer.jpg"

import banerproduct_heatless from "../../imgs/baners/baner_airdrying/heatless-compressed-air-dryers.jpg"

export default function Air_drying() {
    const { products } = useContext(AppContext)
    const [filteredProducts, setFilteredProducts] = useState(products);
    const filterProducts = (type) => {
        if (type === "all") {
            const filtered = products;
            setFilteredProducts(filtered);
        }
        else {
            const filtered = products.filter((product) => product.type === type);
            setFilteredProducts(filtered);
        }
    };

    console.log(products)


    const backgroundStyle = {
        backgroundImage: `url(${banerproduct})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    };
    return (
        <nav className="product_list_air_drying">
            <div style={backgroundStyle} className="baner_product" data-aos="fade-down">
                <Container>
                    <h1>MÁY SẤY KHÍ</h1>
                </Container>
            </div>
            <div>
                <Container className="breadcrumb">
                    <Link to={"/"}>TRANG CHỦ</Link>
                    <Link style={{ color: 'red' }} to={"/compressor"}>/ MÁY SẤY KHÍ </Link>

                </Container>
            </div>
            <Container >
                <div className="air_drying">
                    <Container>
                        <div className="products_home">
                            <div className="product">
                                <img src={banerproduct_dry} />
                                <div className="product_content">
                                    <h2>Máy sấy lạnh</h2>
                                    <p>Máy nén khí bôi trơn bằng dầu của ELGi là xương sống của nhiều ứng dụng công nghiệp khác nhau. Dòng máy nén khí bôi trơn bằng dầu mang đến các giải pháp khí nén lâu dài, tiết kiệm chi phí, phù hợp cho các ứng dụng công nghiệp từ nhẹ đến nặng.</p>
                                    <div className="button">
                                        <Link to={"/"}>
                                            <button className="button_vew">XEM CHI TIẾT</button>
                                        </Link>
                                        <Link to={"/"}>
                                            <button className="button_contact">LIÊN HỆ</button>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <Container>
                            
                            <Row className="row">
                                {products && products.map((product, index) => (
                                    <Col xs={12} sm={6} lg={3} md={4} key={index}>
                                        <Card_product product={product} />
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </Container>
                </div>

                <div className="heatless_dry">
                    <Container>
                        <div className="products_home">
                            <div className="product">
                                <img src={banerproduct_heatless} />
                                <div className="product_content">
                                    <h2>Máy sấy hấp thụ</h2>
                                    <p>Máy nén khí bôi trơn bằng dầu của ELGi là xương sống của nhiều ứng dụng công nghiệp khác nhau. Dòng máy nén khí bôi trơn bằng dầu mang đến các giải pháp khí nén lâu dài, tiết kiệm chi phí, phù hợp cho các ứng dụng công nghiệp từ nhẹ đến nặng.</p>
                                    {/* <p>Xem tất cả</p> */}
                                    <div className="button">
                                        <Link to={"/"}>
                                            <button className="button_vew">XEM CHI TIẾT</button>
                                        </Link>
                                        <Link to={"/"}>
                                            <button className="button_contact">LIÊN HỆ</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </Container>
        </nav >
    )
}