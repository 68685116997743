import { Container, Col, Row } from 'react-bootstrap';
import quan_gia from '../../imgs/baners/quan-gia.jpeg'
import './Quangia.css'

export default function Quangia() {
    const backgroundStyle1 = {
        backgroundImage: `url(${quan_gia})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    };
    return (
        <div>
            <div style={backgroundStyle1} className="baner_contact" data-aos="fade-down">
                <Container>
                    <h1>Dịch vụ quản gia phòng giá rẻ</h1>
                    <h2>Hotline: 0916 811 179</h2>
                </Container>
            </div>
            <div className='content_quangia'>
                <Container>
                    <div>
                        <h2>DỊCH VỤ HỖ TRỢ KHÔNG THƯỜNG XUYÊN</h2>
                        <p>1. Vệ sinh căn hộ (lau dọn vệ sinh sàn nhà, chà rửa Toilet, thay rác, …..) 100k/lần/ căn hộ 1 phòng ngủ, 180k/ lần căn 2 phòng ngủ.(* Khách hàng đặt dịch vụ trước 24h).</p>
                        <p>2. Set up trải grap giường (thay grab, gối, chăn mới…..) gom đồ dơ vào bao theo yêu cầu khách hàng: 60k/ căn 02 giường, 80k/căn 02 giường (khách đặt dịch vụ trước 24h hoặc có thể làm gấp tuỳ khả năng thực tế).</p>
                        <h5>Dịch vụ giữ đồ riêng theo tháng:</h5>
                        <p>Khách hàng có thể gửi đồ dùng cá nhân (vải, drap, chăn…..)  theo thùng bảo quản (  riêng từng khách hàng): 100k/tháng/ thùng.</p>
                        <p>- Thanh toán 5 tháng tặng 01 tháng, 500k/ 6 Tháng </p>
                        <p>- Thanh toán 10 tháng tặng 02 tháng: 1 tr/ 12 tháng.</p>
                        <h4>*** DỊCH VỤ QUẢN GIA DÀNH CHO KHÁCH HÀNG VỪA Ở VỪA KHAI THÁC THEO BẢNG PHÍ RIÊNG</h4>
                    </div>
                </Container>
            </div>
        </div>
    )
}