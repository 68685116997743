import { Container, Col, Row } from 'react-bootstrap';
import quan_gia from '../../imgs/baners/quan-gia.jpeg'
import './Tour.css'
import tour_du_lich from '../../imgs/baners/tour-du-lich.jpeg'
export default function Tour(){
    const backgroundStyle1 = {
        backgroundImage: `url(${tour_du_lich})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    };
    return(
        <div className='page_tour'>
           <div style={backgroundStyle1} className="baner_contact" data-aos="fade-down">
                <Container>
                    <h1>Thiết kế tour du lịch theo yêu cầu</h1>
                    
                </Container>
            </div>
            <div className='content_tour'>
                <Container>
                    <h5>Đang cập nhật...</h5>
                </Container>
            </div>
        </div>
    )
}