import { Container, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import eat_baner from '../../imgs/baners/eatbaner.jpg'
import happyhouse from '../../imgs/baners/nuocuong.png'
import bunbo from '../../imgs/baners/banerbunbo.jpg'
import photruonggiang from '../../imgs/baners/photruonggiang.jpeg'
import garan from '../../imgs/baners/garan.png'
import "./EatApec.css";
export default function EatApec() {
    const backgroundStyle1 = {
        backgroundImage: `url(${eat_baner})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    };
    return (
        <div>
            <div style={backgroundStyle1} className="baner_contact" data-aos="fade-down">
                <Container>
                    <h1>Dịch vụ ăn uống tại Apec</h1>
                </Container>
            </div>
            <div className='content_eatapec'>
                <Container>
                    <Row>
                        <Col xs={12} sm={6} lg={3} md={4}>
                            <Link to={"/otherservices/eatapec/utnhan"}>
                                <div className='Container_eatother'>
                                    <img src={eat_baner}></img>
                                    <h3>Cơm niêu Út Nhân</h3>
                                    {/* <p>Hotline: 0916 811 179</p> */}
                                    <p>Xem menu</p>
                                </div>
                            </Link>
                        </Col>
                        <Col xs={12} sm={6} lg={3} md={4}>
                            <Link to={"/otherservices/eatapec/happyhouse"}>
                                <div className='Container_eatother'>
                                    <img src={happyhouse}></img>
                                    <h3>Các món ăn vặt và nước uống tại Happy House</h3>
                                    {/* <p>Hotline: 0916 811 179</p> */}
                                    <p>Xem menu</p>
                                </div>
                            </Link>
                        </Col>
                        <Col xs={12} sm={6} lg={3} md={4}>
                            <Link to={"/otherservices/eatapec/bunbolongphung"}>
                                <div className='Container_eatother'>
                                    <img src={bunbo}></img>
                                    <h3>Bún bò Huế Long Phụng</h3>
                                    {/* <p>Hotline: 0916 811 179</p> */}
                                    <p>Xem menu</p>
                                </div>
                            </Link>
                        </Col>
                        <Col xs={12} sm={6} lg={3} md={4}>
                            {/* <Link to={"/otherservices/eatapec/bunbolongphung"}> */}
                                <div className='Container_eatother'>
                                    <img src={photruonggiang}></img>
                                    <h3>Phở Trường Giang</h3>
                                    <p>Hotline tư vấn: 0916 811 179</p>
                                    {/* <p>Liên hệ: </p> */}
                                </div>
                            {/* </Link> */}
                        </Col>
                        <Col xs={12} sm={6} lg={3} md={4}>
                            {/* <Link to={"/otherservices/eatapec/bunbolongphung"}> */}
                                <div className='Container_eatother'>
                                    <img src={garan}></img>
                                    <h3>Và còn rất nhiều tiện ích khác</h3>
                                    <p>Hotline tư vấn: 0916 811 179</p>
                                    {/* <p>Liên hệ: </p> */}
                                </div>
                            {/* </Link> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}