import { Container, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import eat_baner from '../../../imgs/baners/nuocuong.png'
import './Happyhouse.css';

import menu from '../../../imgs/restauran/utnhan/menu.jpg'

const images = require.context('../../../imgs/restauran/happyhouse', false, /\.(png|jpg|jpeg|gif)$/);
const imagePaths = images.keys();
const imageArray = imagePaths.map((imagePath) => images(imagePath));


export default function Happyhouse() {
    const backgroundStyle1 = {
        backgroundImage: `url(${eat_baner})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    };
    return (
        <div>
            <div style={backgroundStyle1} className="baner_contact" data-aos="fade-down">
                <Container>
                    <h1 className='title'>Các món ăn vặt và nước uống tại Happy House</h1>
                    <h5>Địa chỉ: DS07, TOÀ DIAMOND, APEC MANDALA CHAM BAY MŨI NÉ</h5>
                </Container>
            </div>
            <div className='content_happyhouse'>
                <Container>
                    <h1>Các loại nước uống</h1>
                    {/* <img src={menu}></img> */}
                    <Row>   
                        {imageArray.map((image, index) => (
                            <Col xs={12} sm={12} lg={12} md={12}>
                                <img
                                    key={index}
                                    src={image}
                                />
                            </Col>
                        ))}
                    </Row>
                   
                </Container>
            </div>
        </div>
    )
}