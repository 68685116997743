import React, { useState } from "react";
import "./Home.css"



import banerhome1 from "../../imgs/baners/banerhome/Home-Banner-1.jpg"
import banerhome2 from "../../imgs/baners/banerhome/Home-Banner-2.jpg"
import banerhome3 from "../../imgs/baners/banerhome/Home-Banner-3.jpg"
import banerhome4 from "../../imgs/baners/banerhome/Home-Banner-4.jpg"

import banerproduct from "../../imgs/baners/banerhome/maynenkhi.jpg"
import banerproduct1 from "../../imgs/baners/banerhome/CS INSTRUMENTS.png"
import banerproduct2 from "../../imgs/baners/banerhome/dust_collectors.png"
import banerproduct4 from "../../imgs/baners/banerhome/nitrogen.jpg"

import icon1 from "../../imgs/icon/icon1.png"
import icon2 from "../../imgs/icon/icon2.png"
import icon3 from "../../imgs/icon/icon3.png"

import baner_filter from "../../imgs/baners/banerhome/filter.jpeg"



import { BsChevronRight } from 'react-icons/bs';
import Slider from "../../components/slider1/Slider"
import { Container, Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"


const images = require.context('../../imgs/khachhang', false, /\.(png|jpg|jpeg|gif)$/);
const imagePaths = images.keys();
const imageArray = imagePaths.map((imagePath) => images(imagePath));

const backgroundStyle1 = {
    backgroundImage: `url(${baner_filter})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
};

const backgroundStyle = {
    backgroundImage: `url(${banerhome1})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
};

export default function Home() {
    const [selectedDate, setSelectedDate] = useState(null);
    const imagesbaner = [
        banerhome1, banerhome2, banerhome3, banerhome4
    ]
    return (
        <div>
            <main>
                <div>
                    <div className="home_slider">
                        <Slider images={imagesbaner} autoSlideInterval={5000} />
                        <Container>
                            <div className="con_content_slider">
                                <h2>GIẢI PHÁP TIẾT KIỆM NĂNG LƯỢNG</h2>
                                <button>
                                    <h3>Liên hệ ngay</h3>
                                </button>
                                
                            </div>
                        </Container>
                    </div>




                    <div className="classify_rooms">
                        <div className="con_classify_rooms">
                            <Container>
                                <div className="con_classify_rooms_title">
                                    <h1>GIẢI PHÁP CỦA CHÚNG TÔI</h1>
                                    <p>- - - - - - -</p>
                                </div>
                                <div className="classify_rooms_items">
                                    <div className="rooms_item" data-aos="fade-up" data-aos-duration="500">
                                        <Link to={"/compressor"}>
                                            <div className="rooms_item_img"><img src={banerproduct} /></div>
                                            <div className="rooms_item_content">
                                                <h3>Máy nén khí</h3>
                                                <p className="xemthem">Xem thêm <BsChevronRight /></p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="rooms_item" data-aos="fade-up" data-aos-duration="500">
                                        <Link to={"/rooms/one-bed"}>
                                            <div className="rooms_item_img"><img src={banerproduct} /></div>
                                            <div className="rooms_item_content">
                                                <h3>Thiết bị & phụ kiện</h3>
                                                <p className="xemthem">Xem thêm <BsChevronRight /></p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="rooms_item" data-aos="fade-up" data-aos-duration="500">
                                        <Link to={"/rooms/one-bed"}>
                                            <div className="rooms_item_img"><img src={banerproduct1} /></div>
                                            <div className="rooms_item_content">
                                                <h3>Thiết bị đo lường</h3>
                                                <p className="xemthem">Xem thêm <BsChevronRight /></p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="rooms_item" data-aos="fade-up" data-aos-duration="500">
                                        <Link to={"/rooms/one-bed"}>
                                            <div className="rooms_item_img"><img src={banerproduct4} /></div>
                                            <div className="rooms_item_content">
                                                <h3>Máy tạo khí</h3>
                                                <p className="xemthem">Xem thêm <BsChevronRight /></p>
                                            </div>
                                        </Link>
                                    </div>


                                </div>
                                <p className="con_classify_rooms_viewall"><Link to={"/rooms"}><p>Xem tất cả</p></Link><BsChevronRight /></p>
                            </Container>

                        </div>
                    </div>

                    <div>
                        <Container>
                            <div className="products_home">
                                <div className="product">
                                    <img src={banerproduct} />
                                    <div className="product_content">
                                        <h2>MÁY NÉN KHÍ</h2>
                                        <p>Máy nén khí bôi trơn bằng dầu của ELGi là xương sống của nhiều ứng dụng công nghiệp khác nhau. Dòng máy nén khí bôi trơn bằng dầu mang đến các giải pháp khí nén lâu dài, tiết kiệm chi phí, phù hợp cho các ứng dụng công nghiệp từ nhẹ đến nặng.</p>
                                        {/* <p>Xem tất cả</p> */}
                                        <Link to={"/compressor"}>
                                        <div className="div_xemthem">
                                            <p className="xemthem">XEM TẤT CẢ<BsChevronRight /></p>
                                        </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div style={backgroundStyle1} className="barner_filter">
                        <Container>
                            <div className="barner_filter_content">
                                <h2>HỆ THỐNG XỬ LÝ KHÍ NÉN</h2>
                                <p>Hỗ trợ phân phối tốt nhất trong ngành có nghĩa là hàng ngàn bộ lọc, vỏ và phụ tùng thay thế có sẵn trực tuyến để mua. Ngoài ra, tham chiếu chéo các bộ phận phù hợp cạnh tranh với tìm kiếm nhanh.</p>
                                <div className="div_xemthem">
                                    <p className="xemthem">XEM TẤT CẢ<BsChevronRight /></p>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div>
                        <Container>
                            <div className="products_home">
                                <div className="product">
                                    <img src={banerproduct2} />
                                    <div className="product_content">
                                        <h2>HỆ THỐNG THU HỒI BỤI CÔNG NGHIỆP</h2>
                                        <p>FIL Việt Nam cung cấp cho các nhà máy hệ thống thu hồi bụi công nghiệp và bộ lọc tiên tiến và đáng tin cậy nhất hiện nay. Việc kết hợp các bộ thu bụi và bộ lọc hàng đầu trong ngành này giúp tăng hiệu quả sản xuất và mang lại môi trường sạch hơn cho nhân viên.</p>
                                        <div className="div_xemthem">
                                            <p className="xemthem">XEM TẤT CẢ<BsChevronRight /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className="why_choose_utilities" style={backgroundStyle} >
                        <div className="con_why_choose_utilities">
                            <Container>
                                <div className="con_why_choose_title_utilities">
                                    <h1>VỀ CHÚNG TÔI</h1>
                                    <p>- - - - - - - - - -</p>
                                    <h6>Chúng tôi cung cấp các giải pháp hoàn chỉnh cho hệ thống khí nén, thu hồi bụi và lọc công nghiệp</h6>
                                </div>
                                <div className="about_us">
                                    <Row>
                                        <Col xs={6} sm={4} lg={3} md={4}>
                                            <div className="why_choose">
                                                <div><img src={icon1} /></div>
                                                <h3>CHẤT LƯỢNG SẢN PHẨM</h3>
                                                <p>Đạt chuẩn ISO</p>
                                            </div>
                                        </Col>
                                        <Col xs={6} sm={4} lg={3} md={4}>
                                            <div className="why_choose">
                                                <div><img src={icon2} /></div>
                                                <h3>HỖ TRỢ TƯ VẤN 24/7</h3>
                                                <p>Phân phối trên toàn quốc</p>
                                            </div>
                                        </Col>
                                        <Col xs={6} sm={4} lg={3} md={4}>
                                            <div className="why_choose">
                                                <div><img src={icon3} /></div>
                                                <h3>BẢO HÀNH UY TÍN</h3>
                                                <p>Bảo hành quy trình chính hãng</p>
                                            </div>
                                        </Col>
                                        <Col xs={6} sm={4} lg={3} md={4}>
                                            <div className="why_choose">
                                                <div><img src={icon1} /></div>
                                                <h3>HỖ TRỢ TOÀN QUỐC</h3>
                                                <p>Đội ngũ trải dài 3 miền</p>
                                            </div>
                                        </Col>

                                    </Row>
                                </div>


                                {/* <div className="utilities">
                                    <div className="utilitie" data-aos="fade-up" data-aos-duration="500">
                                        <div className="reason_icon"><LuParkingCircle style={{ color: '#0077b6' }} /></div>
                                        <h6>Parking</h6>
                                    </div>
                                    <div className="utilitie" data-aos="fade-up" data-aos-duration="700">
                                        <div className="reason_icon"><IoCafeOutline style={{ color: '#283618' }} /></div>
                                        <h6>Cafe</h6>
                                    </div>
                                    <div className="utilitie" data-aos="fade-up" data-aos-duration="900">
                                        <div className="reason_icon"><FaSwimmingPool style={{ color: '#048ba8' }} /></div>
                                        <h6>Swimming Pool</h6>
                                    </div>
                                    <div className="utilitie" data-aos="fade-up" data-aos-duration="1100">
                                        <div className="reason_icon"><BiSpa style={{ color: '#dda15e' }} /></div>
                                        <h6>Spa</h6>
                                    </div>
                                    <div className="utilitie" data-aos="fade-up" data-aos-duration="1100">
                                        <div className="reason_icon" ><IoRestaurantOutline style={{ color: '#fb8500' }} /></div>
                                        <h6>Restaurant</h6>
                                    </div>
                                </div>
                                <div className="utilities">
                                    <div className="utilitie" data-aos="fade-up" data-aos-duration="500">
                                        <div className="reason_icon"><MdOutlineLocalLaundryService /></div>
                                        <h6>In-house Laundry</h6>
                                    </div>
                                    <div className="utilitie" data-aos="fade-up" data-aos-duration="700">
                                        <div className="reason_icon"><CgGym /></div>
                                        <h6>Gym</h6>
                                    </div>
                                    <div className="utilitie" data-aos="fade-up" data-aos-duration="700">
                                        <div className="reason_icon"><LuConciergeBell /></div>
                                        <h6>Room Service</h6>
                                    </div>
                                </div> */}
                            </Container>
                        </div>
                    </div>
                    <div>
                        <Container>
                            <div className="customer_us_title">
                                <h1>KHÁCH HÀNG CỦA CHÚNG TÔI</h1>
                                <p>- - - - - - -</p>
                            </div>
                            <div className="customer_us">
                                <Row>
                                    {imageArray.map((image, index) => (
                                        <Col xs={4} sm={4} lg={3} md={4}>
                                            <img
                                                key={index}
                                                src={image}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </div>

                        </Container>
                    </div>
                    {/* <div className="rooms">
                        <div className="con_rooms">
                            <Container>
                                <div className="rooms_items">
                                    <div >
                                        <h1>Căn hộ một giường ngủ</h1>
                                        <div className="con_rooms_items">
                                            <Row className="row" data-aos="fade-right">
                                            {products
                                                .filter(item => item.type === '1 giường')
                                                .map((item, index) => (
                                                    <Col xs={12} sm={12} lg={6} md={6} key={index}>
                                                        <Card_product product={item} />
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                        <h5>Xem tất cả <BsChevronRight /></h5>
                                    </div>
                                    <div className="rooms_items_homepage">
                                        <h1>Căn hộ hai giường ngủ</h1>
                                        <div className="con_rooms_items">
                                        <Row className="row" data-aos="fade-right">
                                            {products
                                                .filter(item => item.type === '2 giường')
                                                .map((item, index) => (
                                                    <Col xs={12} sm={12} lg={6} md={6} key={index}>
                                                        <Card_product product={item} />
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                        <h5>Xem tất cả <BsChevronRight /></h5>
                                    </div>
                                    <div className="rooms_items_homepage">
                                        <h1>Xem thêm các phòng khác</h1>
                                        <div className="con_rooms_items">
                                        <Row className="row" data-aos="fade-right">
                                            {products.map((item, index) => (
                                                    <Col xs={12} sm={12} lg={6} md={6} key={index}>
                                                        <Card_product product={item} />
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                        <h5>Xem tất cả <BsChevronRight /></h5>
                                    </div>
                                </div>
                                <div className="advertisement">
                                    <div className="advertisement_booknow" data-aos="fade-left">
                                       
                                        <Calender />
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </div> */}
                </div>
            </main>
        </div>
    )
}