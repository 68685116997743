import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTopOnPageChange() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); // Cuộn lên đầu trang mỗi khi location thay đổi
    }, [location]);

    return null; // Component này không render gì cả
}
