import { createContext, useState, useEffect } from "react";
import avatar1107 from '../src/imgs/avatar/avatar1107.jpg'
import avatar0320 from '../src/imgs/avatar/0320.jpg'
import avatarm1528 from '../src/imgs/avatar/1528.jpg'

const images1 = require.context('../public/imgs/1', false, /\.(png|jpg|jpeg|gif)$/);
const imagePaths1 = images1.keys();
const imageArray1107 = imagePaths1.map((imagePath) => images1(imagePath));

const images2 = require.context('../public/imgs/2', false, /\.(png|jpg|jpeg|gif)$/);
const imagePaths2 = images2.keys();
const imageArray0320 = imagePaths2.map((imagePath) => images2(imagePath));

const images3 = require.context('../public/imgs/3', false, /\.(png|jpg|jpeg|gif)$/);
const imagePaths3 = images3.keys();
const imageArraym1528 = imagePaths3.map((imagePath) => images3(imagePath));

export const AppContext = createContext({})
export const AppProvider = ({ children }) => {
  const [show, setShow] = useState(false)
  const handle_click = () => {
    setShow(!show)
  }
  const handle_click_close = () => {
    setShow(false)
  }
  const [showcart, setShowcart] = useState(false)
  const handle_click_cart = () => {
    setShowcart(!showcart)
  }
  const handle_click_cart_close = () => {
    setShowcart(false)
  }
  const [shownav, setShownav] = useState(false)
  const handle_click_menu = () => {
    setShownav(!shownav)
  }
  const close_menu = () => {
    setShownav(false)
  }
  const [backrgoudNav, setBackrgoudNav] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (scrollTop > 0) {
        setBackrgoudNav(true);
      } else {
        setBackrgoudNav(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 500) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const [products, setProducts] = useState([
    {
      id: 1,
      name_cdt: 'LA',
      name: 'Phòng 2 giường',
      phone: '0916 811 179',
      price: 'Liên hệ',
      max_guest: 3,
      room_space: 28,
      bed: 2,
      numberroom: 'R1107',
      sizebed: '1m6',
      view: 'Sea',
      img: avatar1107,
      label: 'view biển',
      type: '2 giường',
      link: 'may-say-khi',
      img_rooms: imageArray1107,
      description: 'Tiêu chuẩn tối đa cho 4 NL , Giá ngày thường 1.4tr / đêm Giá cuối tuần 1.8tr/ đêm ( thứ 6 và thứ 7) Giá trên ko bao gồm ăn sáng Miễn phí bể bơi và trà nước lọc trong phòng (giá phòng có thể thương lượng theo số người và số ngày ở nếu ở từ 2 đêm trở lên )',
    },
    {
      id: 2,
      name_cdt: 'LA',
      name: 'Phòng 1 giường',
      phone: '0916 811 179',
      price: 'Liên hệ',
      max_guest: 3,
      room_space: 28,
      bed: 1,
      numberroom: 'M0320',
      sizebed: '1m8',
      view: 'Sea',
      img: avatar0320,
      label: 'view biển',
      type: '1 giường',
      link: 'may-say-khi',
      img_rooms: imageArray0320,
      description: '',
    },
    {
      id: 3,
      name_cdt: 'LA',
      name: 'Phòng 2 giường',
      phone: '0916 811 179',
      price: 'Liên hệ',
      max_guest: 5,
      room_space: 32,
      bed: 2,
      numberroom: 'M1528',
      sizebed: '1m2 & 1m8',
      view: 'Sea',
      img: avatarm1528,
      label: 'view biển',
      type: '2 giường',
      link: 'may-say-khi',
      img_rooms: imageArraym1528,
      description: '',
    },
  ])

  useEffect(() => {
    if (localStorage.getItem('cart_list')) {
      setCart(JSON.parse(localStorage.getItem('cart_list')))
    }
  }, [])



  const [cart, setCart] = useState([])
  const addCart = (id) => {
    const kq = products.find((item) => item.id == id)
    const index = cart.findIndex((item) => item.id == id)
    if (index >= 0) {
      const newlist = cart;
      newlist[index]["qty"]++
      setCart(newlist)
      localStorage.setItem('cart_list', JSON.stringify(newlist))
    }
    else {
      setCart([...cart, { ...kq, qty: "1" }])
      localStorage.setItem('cart_list', JSON.stringify([...cart, { ...kq, qty: "1" }]))
    }
  }
  const delete1 = (id) => {
    const kq = cart.filter((item) => item.id !== id)
    setCart(kq)
    localStorage.setItem('cart_list', JSON.stringify(kq))
  }
  const changqty = (id, num) => {
    const kq = cart.map((item) => item.id == id && !(num == -1 && item["qty"] == 1) ? { ...item, qty: item["qty"] * 1 + num }
      // && !(item[]
      : item
    );
    setCart(kq)
    localStorage.setItem('cart_list', JSON.stringify(kq))
  };
  const quanlity = (cart.length)
  
  return (
    <AppContext.Provider value={{
      handle_click, handle_click_close, show, setShow, handle_click_cart, handle_click_cart_close, showcart, setShowcart, handle_click_menu, close_menu, shownav, setShownav
      , backrgoudNav, setBackrgoudNav, products, goToTop, showTopBtn, setShowTopBtn, addCart, cart, setCart, quanlity, changqty, delete1
    
    }}>
      {children}
    </AppContext.Provider>
  )

}