import "./Products.css"
import onebed from "../../imgs/baners/One_Bedroom.jpeg"
import twobed from "../../imgs/baners/baner_room_2bed.jpg";
import threebed from "../../imgs/avatar/s1201.jpg"
import baner_room from "../../imgs/baners/baner_room_2bed.jpg";
import hoboi from "../../imgs/baners/ho-boi.jpg";
import Card_product from "./card_product/Card_product";
import { Container, Row, Col } from "react-bootstrap";
import { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import { Link } from "react-router-dom";
import { BsChevronRight } from 'react-icons/bs';
import banerproduct from "../../imgs/baners/baner_maynen/banner.jpg"

import banerproduct_1 from "../../imgs/baners/baner_maynen/OF-Product-home-Landing-Page.jpg"
import banerproduct_2 from "../../imgs/baners/baner_maynen/products-02.jpg"
import banerproduct_3 from "../../imgs/baners/baner_maynen/products-03.png"
import banerproduct_4 from "../../imgs/baners/baner_maynen/EG-Series-75-Category-Images.jpg"

export default function Products() {
    const { products } = useContext(AppContext)
    const [filteredProducts, setFilteredProducts] = useState(products);
    const filterProducts = (type) => {
        if (type === "all") {
            const filtered = products;
            setFilteredProducts(filtered);
        }
        else {
            const filtered = products.filter((product) => product.type === type);
            setFilteredProducts(filtered);
        }
    };
    const backgroundStyle = {
        backgroundImage: `url(${banerproduct})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    };
    return (
        <nav className="product_list">
            <div style={backgroundStyle} className="baner_product" data-aos="fade-down">
                <Container>
                    <h1>MÁY NÉN KHÍ</h1>
                </Container>
            </div>
            <div>
                <Container className="breadcrumb">
                    <Link to={"/"}>TRANG CHỦ </Link>
                    <Link style={{ color: 'red' }} to={"/compressor"}>/ MÁY NÉN KHÍ</Link>
                </Container>
            </div>
            <Container >
                <div>
                    <Container>
                        <div className="products_home">
                            <div className="product">
                                <img src={banerproduct_3} />
                                <div className="product_content">
                                    <h2>Máy nén khí trục vít không dầu</h2>
                                    <p>Máy nén khí bôi trơn bằng dầu của ELGi là xương sống của nhiều ứng dụng công nghiệp khác nhau. Dòng máy nén khí bôi trơn bằng dầu mang đến các giải pháp khí nén lâu dài, tiết kiệm chi phí, phù hợp cho các ứng dụng công nghiệp từ nhẹ đến nặng.</p>
                                    
                                    <Link to={"/compressor/oil-free-compressors"}>
                                        <div className="div_xemthem">
                                            <p className="xemthem">XEM CHI TIẾT<BsChevronRight /></p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <div>
                    <Container>
                        <div className="products_home">
                            <div className="product">

                                <div className="product_content">
                                    <h2>Máy nén khí trục vít có dầu</h2>
                                    <p>Máy nén khí bôi trơn bằng dầu của ELGi là xương sống của nhiều ứng dụng công nghiệp khác nhau. Dòng máy nén khí bôi trơn bằng dầu mang đến các giải pháp khí nén lâu dài, tiết kiệm chi phí, phù hợp cho các ứng dụng công nghiệp từ nhẹ đến nặng.</p>
                                    {/* <p>Xem tất cả</p> */}
                                    <div className="div_xemthem">
                                        <p className="xemthem">XEM CHI TIẾT<BsChevronRight /></p>
                                    </div>
                                </div>
                                <img src={banerproduct_4} />
                            </div>
                        </div>
                    </Container>
                </div>
                <div>
                    <Container>
                        <div className="products_home">
                            <div className="product">
                                <img src={banerproduct_1} />
                                <div className="product_content">
                                    <h2>Máy nén khí piston</h2>
                                    <p>Máy nén khí bôi trơn bằng dầu của ELGi là xương sống của nhiều ứng dụng công nghiệp khác nhau. Dòng máy nén khí bôi trơn bằng dầu mang đến các giải pháp khí nén lâu dài, tiết kiệm chi phí, phù hợp cho các ứng dụng công nghiệp từ nhẹ đến nặng.</p>
                                    {/* <p>Xem tất cả</p> */}
                                    <div className="div_xemthem">
                                        <p className="xemthem">XEM CHI TIẾT<BsChevronRight /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <div>
                    <Container>
                        <div className="products_home">
                            <div className="product">

                                <div className="product_content">
                                    <h2>Máy nén khí di động</h2>
                                    <p>Máy nén khí bôi trơn bằng dầu của ELGi là xương sống của nhiều ứng dụng công nghiệp khác nhau. Dòng máy nén khí bôi trơn bằng dầu mang đến các giải pháp khí nén lâu dài, tiết kiệm chi phí, phù hợp cho các ứng dụng công nghiệp từ nhẹ đến nặng.</p>
                                    {/* <p>Xem tất cả</p> */}
                                    <div className="div_xemthem">
                                        <p className="xemthem">XEM CHI TIẾT<BsChevronRight /></p>
                                    </div>
                                </div>
                                <img src={banerproduct_2} />
                            </div>
                        </div>
                    </Container>
                </div>
            </Container>
        </nav >
    )
}