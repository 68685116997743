import { Container, Col, Row } from 'react-bootstrap';
import quan_gia from '../../imgs/baners/quan-gia.jpeg'
import './Giatui.css'
import giat_ui from '../../imgs/baners/giat-ui.jpeg'
import banggiagiatui from '../../imgs/imgother/banggiagiatui.png'

import { useEffect, useState } from "react"
import axios from 'axios';
import { Link, Outlet, useParams } from "react-router-dom"
export default function Giatui() {
    const backgroundStyle1 = {
        backgroundImage: `url(${giat_ui})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    };

    const apikey = "7e75c1728e2f7864a5ffa1a5df9c9d86"
    // const apikey = "4e44d9029b1270a757cddc766a1bcb63"

    // const [data, setData] = useState(null)
    const [movieList, setMovieList] = useState([]);
    const { type } = useParams();

    const getData = async () => {
        const url = `https://dreampos.vn/san-pham/`;
        try {
            const res = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    // Thêm các header khác nếu cần thiết
                },
                // Thêm các tùy chọn khác nếu cần
            });
            console.log('Dữ liệu trả về:', res.data);
            setMovieList(res.data.results);
        } catch (error) {
            console.error('Lỗi khi lấy dữ liệu:', error);
            if (error.response) {
                // Máy chủ đã trả lời với trạng thái khác 2xx
                console.error('Phản hồi lỗi:', error.response.data);
                console.error('Trạng thái:', error.response.status);
                console.error('Header:', error.response.headers);
            } else if (error.request) {
                // Yêu cầu đã được gửi nhưng không có phản hồi
                console.error('Yêu cầu không có phản hồi:', error.request);
            } else {
                // Đã xảy ra lỗi khi thiết lập yêu cầu
                console.error('Lỗi thiết lập yêu cầu:', error.message);
            }
        }
    };

    useEffect(() => {
        getData();
    }, [type]);

    console.log(movieList);

    return (
        <div>
            <div style={backgroundStyle1} className="baner_contact" data-aos="fade-down">
                <Container>
                    <h1>Dịch vụ giặt ủi HAPPY HOUSE DS07</h1>
                    <h2>Hotline: 0388 509 027</h2>
                </Container>
            </div>
            <div className='content_giatui'>
                <Container>
                    {/* <img src={banggiagiatui}></img> */}
                    <table>
                        <tr>
                            <th>
                                STT
                            </th>
                            <th>
                                TÊN DICH VỤ
                            </th>
                            <th>
                                ĐƠN GIÁ
                            </th>
                            <th>
                                GHI CHÚ
                            </th>
                            <th>
                                Thời gian hoàn thành
                            </th>
                            <th>
                                VIP
                            </th>

                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Giặt sấy quần áo thường</td>
                            <td>25k/kg</td>
                            <td>Đơn hàng tối thiểu tính 100k (dưới 4kg)</td>
                            <td>12 tiếng sau khi nhận đồ</td>
                            <td rowspan="3">Dịch vụ giặt nhanh, lấy sau 4 đến 5h ngay khi gửi đồ (phụ thu 30% so với đơn giá)</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Giặt sấy vải trắng</td>
                            <td>40k/kg</td>
                            <td>Đơn hàng tối thiểu 100k (dưới 2kg)</td>
                            <td>12 tiếng sau khi nhận đồ</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Đồ lót (ko nhận áo ngực gọng) vì giặt máy, vớ chân 10k/đôi</td>
                            <td>10k/cái</td>
                            <td>Chỉ nhận kèm khi khách yêu cầu giặt quần áo (không nhận riêng lẽ đồ lót không)</td>
                            <td>12 tiếng sau khi nhận đồ</td>
                        </tr>
                        <tr><th colSpan={6}>**** Mỗi mẻ giặt đồ, khởi động máy giặt, sấy nên mức thu tối tiểu 100k.
                            <p>**** Phí giao quần áo tận phòng: 30k/lần</p>
                        </th></tr>
                        <tr>
                            <td>4</td>
                            <td>Ủi thẳng chân váy, đầm…</td>
                            <td>25k/cái</td>
                            <td></td>
                            <td rowspan="3">Ngay sau khi ủi xong, tuỳ số lượng quần áo, khoảng 1h </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Ủi thẳng quần áo thun bình thường</td>
                            <td>20k/cái</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>Ủi thẳng sơ mi</td>
                            <td>30k/cái</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                </Container>
            </div>
        </div>
    )
}