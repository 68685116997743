import { Container, Col, Row } from 'react-bootstrap';
import { AppContext } from '../../../AppContext';
import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import Card_product from '../card_product/Card_product';
import './Rooms.css'
import baner_room from "../../../imgs/baners/baner_room.jpg"
export default function Rooms() {
    const { products } = useContext(AppContext);
    const [productt, setProductt] = useState([]);
    const location = useLocation();

    const path = location.pathname;
    const link = path.substring(path.lastIndexOf("/") + 1);

    useEffect(() => {
        const filteredProducts = products.filter(item => item.link === link);
        setProductt(filteredProducts);
    }, [link, products]);
    
    const backgroundStyle1 = {
        backgroundImage: `url(${baner_room})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    };

    return (
        <div>
            <div style={backgroundStyle1} className="baner_contact" data-aos="fade-down">
                <Container>
                    <h1>MÁY NÉN KHÍ</h1>
                </Container>
            </div>
            <div>
                <nav className="product_ditail_bottom_list">
                    <Container>
                        <Row className="row">
                            {productt && productt.map((product, index) => (
                                <Col xs={12} sm={6} lg={3} md={4} key={index}>
                                    <Card_product product={product} />
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </nav>
            </div>
        </div>
    );
}
