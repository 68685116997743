import { Container, Col, Row } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import eat_baner from '../../../imgs/baners/banerbunbo.jpg'
import bunboimg from '../../../imgs/baners/bunbo1.jpg'
import './BunboLongPhung.css';

import menu from '../../../imgs/restauran/utnhan/menu.jpg'

export default function BunboLongPhung() {
    const backgroundStyle1 = {
        backgroundImage: `url(${eat_baner})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    };
    return (
        <div>
            <div style={backgroundStyle1} className="baner_contact" data-aos="fade-down">
                <Container>
                    <h1>Quán bún bò Huế Long Phụng</h1>
                    {/* <h5>Địa chỉ: DS07, TOÀ DIAMOND, APEC MANDALA CHAM BAY MŨI NÉ</h5> */}
                </Container>
            </div>
            <div className='content_bunbo'>
                <Container>
                    {/* <h1>Các loại nước uống</h1> */}
                    {/* <img src={menu}></img> */}
                    <Row>
                        <Col xs={12} sm={6} lg={3} md={4}>
                            <img src={bunboimg} />
                        </Col>
                        <Col xs={12} sm={6} lg={4} md={4}>
                            <table>
                                <tr>
                                    <th colSpan={2}>Menu bún bò</th>
                                </tr>
                                <tr>
                                    <th>Món</th>
                                    <th>Giá</th>
                                </tr>
                                <tr>
                                    <td>Bún bò</td>
                                    <td>70k</td>
                                </tr>
                                <tr>
                                    <td>Bún bò đặc biệt</td>
                                    <td>80k</td>
                                </tr>
                                <tr>
                                    <th colSpan={2}>Menu bồ câu</th>
                                </tr>
                                <tr>
                                    <td>Cháo bồ câu</td>
                                    <td>190k</td>
                                </tr>
                                <tr>
                                    <td>Bồ câu tiềm</td>
                                    <td>190k</td>
                                </tr>
                            </table>
                        </Col>
                    </Row>
                    {/* <h1>Các món ăn vặt</h1> */}
                </Container>
            </div>
        </div>
    )
}