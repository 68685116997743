import { Container, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import eat_baner from '../../../imgs/baners/eatbaner.jpg'
import './Utnhan.css';

import menu from '../../../imgs/restauran/utnhan/menu.jpg'

const images = require.context('../../../imgs/restauran/utnhan', false, /\.(png|jpg|jpeg|gif)$/);
const imagePaths = images.keys();
const imageArray = imagePaths.map((imagePath) => images(imagePath));


export default function Utnhan() {
    const backgroundStyle1 = {
        backgroundImage: `url(${eat_baner})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    };
    return (
        <div>
            <div style={backgroundStyle1} className="baner_contact" data-aos="fade-down">
                <Container>
                    <h1>Cơm niêu Út Nhân</h1>
                    <h5>Địa chỉ: Đ. Xuân Thủy, khu phố Long Sơn, Thành phố Phan Thiết, Bình Thuận</h5>
                </Container>
            </div>
            <div className='content_utnhan'>
                <Container>
                    <img src={menu}></img>
                    <Row>
                        {imageArray.map((image, index) => (
                            <Col xs={12} sm={6} lg={3} md={4}>
                                <img
                                    key={index}
                                    src={image}
                                />
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </div>
    )
}