import { Container, Col, Row } from 'react-bootstrap';
import bbq from '../../imgs/baners/bbq.jpg'
import './Camtrai.css'
import banercamtrai from '../../imgs/baners/camtrai1.jpg'
import banercamtrai1 from '../../imgs/baners/bbq.jpg'
import banercamtrai2 from '../../imgs/baners/banercamtrai.jpg'
export default function Camtrai() {
    const backgroundStyle1 = {
        backgroundImage: `url(${banercamtrai})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    };
    return (
        <div>
            <div style={backgroundStyle1} className="baner_contact" data-aos="fade-down">
                <Container>
                    <h1>Thiết kế tổ chức cắm trại, gala dinner, tiệc BBQ theo yêu cầu</h1>
                    <h2>Hotline: 0916 811 179</h2>
                </Container>
            </div>
            <div className='content_camtrai'>
                <Container>
                    <Row>
                        <Col xs={12} sm={6} lg={4} md={4}>
                            <img src={banercamtrai} />
                        </Col>
                        <Col xs={12} sm={6} lg={4} md={4}>
                            <img src={banercamtrai1} />
                        </Col>
                        <Col xs={12} sm={6} lg={4} md={4}>
                            <img src={banercamtrai2} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}