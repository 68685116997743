import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/home/Home';
import Products from './components/products/Products';
import Oil_free_compressors from './components/products/oil_free_compress/Oil_free_compressors';
import Air_drying from './pages/air_drying/Air_drying'


import Contact from './pages/contact/Contact';
import { AppProvider } from './AppContext';
import Aos from 'aos';
import { useEffect } from 'react';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Product_ditail from './pages/products/product_ditail/Product_ditail';
import Booking from './pages/booking/Booking';
import Rooms from './components/products/rooms/Rooms';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import ScrollToTopOnPageChange from './components/scrolltotop/ScrollToTopOnPageChange';
import Otherservices from './pages/otherservices/Otherservices';
import Quangia from './pages/quangia/Quangia';
import Camtrai from './pages/camtrai/Camtrai';
import Tour from './pages/tour/Tour';
import Canho5sao from './pages/canho5sao/Canho5sao';
import Giatui from './pages/giatui/Giatui';
import EatApec from './pages/eatApec/EatApec';
import Utnhan from './pages/eatApec/utnhan/Utnhan';
import Happyhouse from './pages/eatApec/happyhouse/Happyhouse';
import BunboLongPhung from './pages/eatApec/bunbolongphung/BunboLongPhung';
function App() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div>
      <AppProvider>
        <Header />
        <ScrollToTop />
        <ScrollToTopOnPageChange />
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/booking' element={<Booking />}></Route>
          <Route path='/compressor' element={<Products />}></Route>

          <Route path='/compressor/oil-free-compressors' element={<Oil_free_compressors />}></Route>

          <Route path='/may-say-khi' element={<Air_drying />}></Route>




          <Route path='/otherservices' element={<Otherservices />}></Route>
          <Route path='/otherservices/quangia' element={<Quangia />}></Route>
          <Route path='/otherservices/giatui' element={<Giatui />}></Route>
          <Route path='/otherservices/camtrai' element={<Camtrai />}></Route>
          <Route path='/otherservices/tour' element={<Tour />}></Route>
          <Route path='/otherservices/canho5sao' element={<Canho5sao />}></Route>
          <Route path='/otherservices/eatapec' element={<EatApec />}></Route>
          <Route path='/otherservices/eatapec/utnhan' element={<Utnhan />}></Route>
          <Route path='/otherservices/eatapec/happyhouse' element={<Happyhouse />}></Route>
          <Route path='/otherservices/eatapec/bunbolongphung' element={<BunboLongPhung />}></Route>
          
          <Route path='/rooms/:link' element={<Rooms />}></Route>
          <Route path='/rooms/:link/:id' element={<Product_ditail />}></Route>
          <Route path='/contact' element={<Contact />}></Route>
        </Routes>
        <Footer />
      </AppProvider>
    </div>
  );
}
export default App;
