import { Container, Col, Row } from 'react-bootstrap';
import quan_gia from '../../imgs/baners/quan-gia.jpeg'
import './Canho5sao.css'
import sao5 from '../../imgs/baners/5sao.jpeg'
export default function Canho5sao() {
    const backgroundStyle1 = {
        backgroundImage: `url(${sao5})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    };
    return (
        <div>
            <div style={backgroundStyle1} className="baner_contact" data-aos="fade-down">
                <Container>
                    <h1>Căn hộ dịch vụ chuẩn 5 sao</h1>

                </Container>
            </div>
            <div className='content_5sao'>
                <Container>
                    <h5>Đang cập nhật...</h5>
                </Container>
            </div>
        </div>
    )
}